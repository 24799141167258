// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Spinner, Box, Flex, Spacer,
  useColorModeValue,
  useToast, Img, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton
} from "@chakra-ui/react";

import { Tag, TagLeftIcon, TagLabel } from '@chakra-ui/react'
import { SlEnergy } from "react-icons/sl";
import { IoWaterOutline } from "react-icons/io5";

import moment from 'moment'

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { handleGetMPoints, setMPointStatus } from "redux/measurement_points";
import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'

const LocationTable = ({ title, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const toast = useToast()

  const dispatch = useDispatch()

  const status =  useSelector(state => state.mpoints.status)
  const clearStatus = () => dispatch(setMPointStatus(STATUS_NONE))
  const error =  useSelector(state => state.mpoints.error)
  const mpoints =  useSelector(state => state.mpoints.mpoints)
  const orgId = useSelector(state => state.app.userOrgID)


  const [mpointsData, setMpointsData] = useState([])

  useEffect( () => {
    toast.closeAll()
    dispatch(handleGetMPoints(orgId))
  }, [])

  useEffect(() => {
    switch(status) {
      case STATUS_LOADING:
        setMpointsData([])
        //console.log("AAAAAA", mpointsData)
        //toast.closeAll()
        break;
      case STATUS_SUCCESS:
        toast.closeAll()
        /*
        toast({
          title: 'Locations Loaded',
          status: 'success',
          isClosable: true,
          position: "top",
        })*/
        clearStatus()
        break;
      case STATUS_ERROR:
        toast.closeAll()
        if(error.detail !== "") {
          /*
          toast({
            title: error.detail,
            status: 'error',
            isClosable: true,
            position: "top",
          })
          */

        }
        break;
    }
  }, [status])

  useEffect(() => {
    setMpointsData(mpoints.results)
  }, [mpoints])

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalImage, setModalImage] = useState("")

  const getPointType = (pointType) => {
    let pointIcon
    switch(pointType) {
      case 'emeter':
        pointIcon = <Tag size={'lg'} variant='outline' ><TagLeftIcon  as={SlEnergy} boxSize={6} /><TagLabel>Electricity</TagLabel></Tag>
        break;
      case 'wmeter':
        pointIcon = <Tag size={'lg'} variant='outline' ><TagLeftIcon  as={IoWaterOutline} boxSize={6} /><TagLabel>Water</TagLabel></Tag>
        break;
      default:
        pointIcon = <Text> </Text>
        break;
    }
    return pointIcon
  }

  const openModal = (img) => {
    setModalImage(img)
    onOpen()
  }

  return (
    <Card >
      <CardHeader p='6px 0px 22px 0px' width="100%">
          <Box>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>{title}</Text>
          </Box>
          <Spacer />
          <Box>{status == STATUS_LOADING ? <Spinner size="lg" /> : null }</Box>
      </CardHeader>
      <CardBody maxHeight="calc(80vh - 14px)" overflowY={{ sm: "scroll", xl: "scroll" }}>
        <Table variant='simple' color={textColor} >
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx+String(parseInt(Math.random()*1000000))} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody >
            {mpointsData !== undefined ? mpointsData.map((row) => {
              return (
                  <TablesTableRow
                    id={row.location.id}
                    key={row.location.name +'-'+ String(parseInt(Math.random()*1000000))}
                    measurementPoint={row}
                    name={row.location.name}
                    code={row.name}
                    logo={row.location.logo}
                    defaultImage={row.album !== null && row.location.album.images.length > 0 ? row.location.album.images[0].image : ''}
                    magnifyDefaultImage={(img) => openModal(img)}
                    pointType={row.point_type}
                    pointTypeIcon={getPointType(row.point_type)}
                    activation_date={moment(row.created_on).format("MMM DD, YYYY")}
                    status={"online"}
                  />
              );
            }) : null}
          </Tbody>
        </Table>
      </CardBody>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={6}>
            <Img src={modalImage} w="500px" borderRadius="12px" me="18px" />
          </ModalBody>
          <ModalCloseButton/>

        </ModalContent>
      </Modal>
    </Card>
  );
};
export default LocationTable
